import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";

export type TypeArticleTypeFields = {
  title?: EntryFieldTypes.Symbol;
  disclaimer?: EntryFieldTypes.Text;
};

export type TypeArticleTypeSkeleton = EntrySkeletonType<TypeArticleTypeFields, "articleType">;
export type TypeArticleType<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeArticleTypeSkeleton, Modifiers, Locales>;

export function isTypeArticleType<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeArticleType<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === "articleType";
}
