import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";

export type TypeVideoTypeFields = {
  title?: EntryFieldTypes.Symbol;
  slug?: EntryFieldTypes.Symbol;
};

export type TypeVideoTypeSkeleton = EntrySkeletonType<TypeVideoTypeFields, "videoType">;
export type TypeVideoType<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeVideoTypeSkeleton, Modifiers, Locales>;

export function isTypeVideoType<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeVideoType<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === "videoType";
}
